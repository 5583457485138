<template>
  <div class="mainconunte">
    <rxNavBar title="考勤列表"></rxNavBar>
    <!-- 日期 -->
    <div class="part-inputpart" style="margin-top: 10px">
        <div class="expectedDatePanelss">
            <div id="expectedDatePanel" class="part-inputpart-row" @click="clickExpectedDateShow">
                <span :class=" 0==houseInfo.expectedDate.trim().length ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-enablenon part-inputpart-row-header">选择月份</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext" :class="0==houseInfo.expectedDate ? '': 'part-inputpart-row-redtext' ">{{houseInfo.expectedDate}}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png" >
            </div>
        </div>
        <div>
            <van-popup v-model="totalResultShow" position="bottom" class="selectTime">
                <div>
                    <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月日" item-height="35px"
                        @confirm="expectedDateConfirm" @cancel="expectedDateCancel" />
                </div>
            </van-popup>
        </div>
    </div>
    <div class="part-inputpart expectedDatePanelss" style="margin-bottom: 10px;color: #ff5d3b;font-weight: 600;" v-if="(formDataInfo.dataConfirmation) && (formDataInfo.dataConfirmation != '2')">
        当前考勤人,数据暂未经人事确认,仅供参考
    </div>
    <!-- 基本信息 -->
    <div class="dataDetailMain">
        <div class="selfMain">
            <van-divider>基本信息</van-divider>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;display:flex">应出勤天数：<span style="color: gray;">{{formDataInfo.needWorkDay ? formDataInfo.needWorkDay : 0}}天</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;">实际出勤天数：<span style="color: gray;">{{formDataInfo.actualWorkDay ? formDataInfo.actualWorkDay : 0}}天</span></div>
            </div>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">轻微迟到次数：<span style="color: gray;">{{formDataInfo.slightLateNum ? formDataInfo.slightLateNum : 0}}次</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px">严重迟到次数：<span style="color: gray;">{{formDataInfo.seriousLateNum ? formDataInfo.seriousLateNum : 0}}次</span></div>
            </div>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">轻微早退次数：<span style="color: gray;">{{formDataInfo.slightEarlyNum ? formDataInfo.slightEarlyNum : 0}}次</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px">严重早退次数：<span style="color: gray;">{{formDataInfo.seriousEarlyNum ? formDataInfo.seriousEarlyNum : 0}}次</span></div>
            </div>
            <!-- <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">轻微迟到早退次数：<span style="color: gray;">{{formDataInfo.slightLateOrEarlyNum ? formDataInfo.slightLateOrEarlyNum : 0}}次</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px">严重迟到早退次数：<span style="color: gray;">{{formDataInfo.seriousLateOrEarlyNum ? formDataInfo.seriousLateOrEarlyNum : 0}}次</span></div>
            </div> -->
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">旷工天数：<span style="color: gray;">{{formDataInfo.absenteeismDay ? formDataInfo.absenteeismDay : 0}}天</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px">缺卡次数：<span style="color: gray;">{{formDataInfo.missingCardDay ? formDataInfo.missingCardDay : 0}}次</span></div>
            </div>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">事假天数：<span style="color: gray;">{{formDataInfo.leaveOfAbsenceDays ? formDataInfo.leaveOfAbsenceDays : 0}}天</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px">年假天数：<span style="color: gray;">{{formDataInfo.annualLeaveDays ? formDataInfo.annualLeaveDays : 0}}天</span></div>
            </div>
             <div style="display:flex">
                
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">病假天数：<span style="color: gray;">{{formDataInfo.sickLeaveDays ? formDataInfo.sickLeaveDays : 0}}天</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px">带薪病假天数：<span style="color: gray;">{{formDataInfo.paidSickLeaveDays ? formDataInfo.paidSickLeaveDays : 0}}天</span></div>
            </div>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">婚假天数：<span style="color: gray;">{{formDataInfo.marriageLeaveDays ? formDataInfo.marriageLeaveDays : 0}}天</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px;">产假天数：<span style="color: gray;">{{formDataInfo.maternityLeaveDays ? formDataInfo.maternityLeaveDays : 0}}天</span></div>
            </div>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">陪产假天数：<span style="color: gray;">{{formDataInfo.paternityLeaveDays ? formDataInfo.paternityLeaveDays : 0}}天</span></div>
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px">产检天数：<span style="color: gray;">{{formDataInfo.prenatalExamDays ? formDataInfo.prenatalExamDays : 0}}天</span></div>
            </div>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px">丧假天数：<span style="color: gray;">{{formDataInfo.funeralLeaveDays ? formDataInfo.funeralLeaveDays : 0}}天</span></div>
                
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-top:5px;">调休天数：<span style="color: gray;">{{formDataInfo.compensatoryLeaveDays ? formDataInfo.compensatoryLeaveDays : 0}}天</span></div>
            </div>
            <div style="display:flex">
                <div style="flex:1;font-size: 14px;font-weight: 600;margin-left:20px;margin-top:5px;">加班天数：<span style="color: gray;">{{formDataInfo.overtimeDays ? formDataInfo.overtimeDays : 0}}天</span></div>
            </div>
        </div>
    </div>
    <!-- 列表 -->
    <div class="dataDetailMain">

        <div class="dataDetail">
            <!-- <div class="dataStaffName">
                <div style="font-size:14px;">日期</div>
                <div v-for="item,index in additionList" :key="index">{{ item.roomFuzzyAddress }}</div>
            </div> -->
            <div class="staffData">
                <div class="dataDetailRightDataRow" >
                    <div class="dataDetailRightData1 fontblod">考勤日期</div>
                    <div class="dataDetailRightData fontblod">上午打卡</div>
                    <div class="dataDetailRightData2 fontblod">下午打卡</div>
                </div>
                <div v-for="item in additionList" :key="item.id" class="dataDetailRightDataRow">
                    <div class="dataDetailRightData1">{{ item.checkDate + '(' +  item.dayOfWeek + ')' }}</div>
                    <div class="dataDetailRightData" :style="item.realworkCheckstatus_Display == '正常' ? nomal : redFonts">{{ item.realworkCheckstatus_Display }}</div>
                    <div class="dataDetailRightData2" :style="item.realworkCheckstatus_Displays == '正常' ? nomal : redFonts">{{ item.realworkCheckstatus_Displays }}</div>
                </div>
                <div style="height: 150px;line-height:150px" class="dataDetailMain" v-if="additionList.length == 0">
                    暂无数据
                </div>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import { DatetimePicker,Popup,Divider} from 'vant'
import {checkAndroid, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {queryWorkCheckDetailMob} from '../../../getData/getData'
export default {
    name: 'workCheckindex',
    data() {
        return {
            totalResultShow: false,
            isExpectedDateShow: false,
            currentDate: new Date(), //函数
            houseInfo: {
                expectedDate: '',
                money: '0',
                month: new Date().getMonth()+1
            },
            nomal: {},
            redFonts: {
                color: 'red'
            },
            additionList: [
            ],
            formDataInfo: {}

        }
    },
    components: {
        rxNavBar,
        [DatetimePicker.name]: DatetimePicker,
        [Popup.name]: Popup,
        [Divider.name]: Divider,
    },
    mounted(){
        
    },
    activated() {
        var date = new Date()
        if (date.getMonth() < 9) {
            this.houseInfo.expectedDate = date.getFullYear() + '-' + '0' + (date.getMonth() + 1)

        } else {
            this.houseInfo.expectedDate = date.getFullYear() + '-' + (date.getMonth() + 1)
        }
        this.initDataInfo()
    },
    methods: {
        initDataInfo() {
            var that = this
            const params = {
                staff_id: getStaffId(),
                dateStr: that.houseInfo.expectedDate
            }
            queryWorkCheckDetailMob(params).then(res=> {
                responseUtil.dealResponse(that, res, () => {
                    responseUtil.alertOK(that)
                    that.additionList = res.data.data.detail ? res.data.data.detail : []
                    that.formDataInfo = res.data.data.workcheckMap ? res.data.data.workcheckMap : {}
                })
            })
        },
        clickExpectedDateShow() {
            this.totalResultShow = !this.totalResultShow
            this.isExpectedDateShow = !this.isExpectedDateShow;
            var panel = document.getElementById('expectedDatePanel')
            if (panel) {
                document.addEventListener('click', e => {
                    if (!panel.contains(e.target)) {
                        this.isExpectedDateShow = false
                    }
                })
            }
        },
        //日期选择组件 点击确认键后执行方法
        expectedDateConfirm(value) {
            var date = new Date(value)
            if (date.getMonth() < 9) {
                this.houseInfo.expectedDate = date.getFullYear() + '-' + '0' + (date.getMonth() + 1)

            } else {
                this.houseInfo.expectedDate = date.getFullYear() + '-' + (date.getMonth() + 1)
            }
            this.houseInfo.month = date.getMonth() + 1
            this.currentDate = value
            this.isExpectedDateShow = false
            this.totalResultShow = false
            this.initDataInfo()
            //初始化数据

        },
        expectedDateCancel() {
            this.isExpectedDateShow = false
            this.totalResultShow = false
        },
    }
}
</script>

<style scoped lang="less">
    .mainconunte {
        height: 100vh;
        background: #f8f8f8;
        overflow: auto;
    }
    .part-inputpart {
		display: flex;
		flex-direction: column;
		border-radius: 8px;
		white-space: nowrap;
		overflow: hidden;
		font-size: 15px;
	}
    .expectedDatePanelss {
       width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
	.part-inputpart-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 40px;
		background-color: #ffffff;
		margin-top: 2px;
		width: 94%;
		height: 50px;
		border-radius: 8px;
		// margin-left: 20px;
		margin-bottom: 20px;
	}

	.part-inputpart-row-header {
		font-weight: 800;
	}

	.part-inputpart-row input {
		border: 0;
		width: 100%;
	}

	.part-inputpart-dropDownList {
		height: 23%;
		/*垂直方向滚动*/
		/*overflow-y: scroll;*/
		overflow: auto;
		width: 92.5%;
		position: absolute;
		margin-left: 20px;
		height: 185px;
		width: 335px;
		z-index: 99;
	}

	

	.part-inputpart-row-graytext {
		color: #d8d8d8;
		width: 100%;
	}


	.part-inputpart-row-redtext {
		width: 100%;
		color: #ff5d3b;
		font-weight: 600;
	}

	.part-inputpart-row-right {
		float: right;
		margin-right: 10px;
	}


	.part-inputpart-row-right-timepick {
		width: 20px;
	}
    .content-divide {
		font-size: 12px;
		color: #efefef;
		margin: 0 10px;
	}

    .content-have {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #ff5d3b;
	}

	.content-none {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #c7c7c7;
	}
    .selfMain {
        width: 94%;
        padding: 10px 0;
        background: #fff;
        border-radius: 7.5px;
        // height: 200px;
        margin: 0 0 20px 0;
    }
    .dataDetailMain {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fontblod {
        font-weight: bold;
    }
    .dataDetail{  
        width: 94%;
        font-size: 12px;
        // padding: 10px 0;
        display: flex;
        border-radius: 7.5px;
        background-color: #FFFFFF;
        
        .dataStaffName{  
            display: flex;
            flex-direction: column;

            // border-top: 1px solid #f8f8f8;
            >div:nth-of-type(1){
                font-weight: bolder;
                border-left: 1px solid #f8f8f8;
                border-right: 1px solid #f8f8f8;
                border-bottom: 1px solid #f8f8f8;
            }
            >div:nth-of-type(even){
                background-color: #fff;
                border-left: 1px solid #f8f8f8;
                border-right: 1px solid #f8f8f8;
                border-bottom: 1px solid #f8f8f8;
            }
            >div{
                padding: 0 10px;
                // font-size: 14px;
                width: 150px;
                overflow: hidden;
                height: 33px;
                line-height: 33px;
                border-left: 1px solid #f8f8f8;
                border-right: 1px solid #f8f8f8;
                border-bottom: 1px solid #f8f8f8;
            }
        }
        .staffData{  //右侧数据
            width: 100%;
            // width: 80%;
            // display: inline-block;
            overflow:hidden;
            white-space:nowrap;
            overflow-x: scroll;
            display: flex;
            flex-direction: column;
            
            
            .dataDetailRightDataRow:nth-of-type(even){
                background-color: #fff;
                
                 
                >.dataDetailRightData{
                    background-color: #fff;
                    
                    
                }
                >.dataDetailRightData1 {
                  background-color: #fff;
                   border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                    
                }
                >.dataDetailRightData2 {
                    background-color: #fff;
                    border-bottom: 1px solid #f8f8f8;

                }
            }
            .dataDetailRightDataRow{  //右侧数据横hang
                height: 33px;
                line-height: 33px;
                    border-bottom: 1px solid #f8f8f8;
                .dataDetailRightData{     //右侧每个数据
                    display:inline-block;
                    width: 23%;
                    padding-left: 15px;
                    // background: red;
                    overflow: hidden;
                    font-size: 12px;
                    height: 33px;
                     border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                }
                .dataDetailRightData1 {
                    display:inline-block;
                    width: 38%;
                    padding-left: 15px;
                    overflow: hidden;
                    font-size: 12px;
                    height: 33px;
                     border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                    
                }
                .dataDetailRightData2 {
                    display:inline-block;
                    width: 23%;
                    padding-left: 15px;
                    overflow: hidden;
                    font-size: 12px;
                    height: 33px;
                    border-bottom: 1px solid #f8f8f8;
                }
                
            }
        }

    } 
</style>